import { OnInit } from '@angular/core';
import { Component } from '@angular/core';
import { angularEditorConfig } from '@kolkov/angular-editor/lib/config';
import { AuthenticationService } from './provider/authentication-service';
import { Storage } from '@ionic/storage';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'ChangeTomorrow';

  constructor(private authenticationService: AuthenticationService, private storage:Storage) {
    localStorage.removeItem('divid');
	 // localStorage.clear();
     this.storage.clear().then(() => {
      console.log('all keys are cleared');
    });
  }

  ngOnInit(): void {
    //temp fix for authGuard on page reload
    //remove when session management implemented
    let _getAuthStr = localStorage.getItem("authenticationdata");
    if (_getAuthStr != null) {
      const authdata = JSON.parse(_getAuthStr);
      if (typeof(authdata['profile']) != undefined) {
        if (authdata['profile'] != null) {
          this.authenticationService.loggeduser(authdata['profile']);
        }
      }
    }

  }
}
