export const environment = {
  production: false,
  api: 'http://52.0.123.55/',
  apiURL : 'http://52.0.123.55/changetomorrow/',
  googlesitekey:'6LcZbkgaAAAAACuOzxK2E4mbaW7bnS15nT9BLfzD'
};
export const dev_userpool_keys = {
  accessKeyId: 'AKIARCQVBVYPFITEXU6O',
  secretAccessKey: '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',
  region: 'us-east-1',
  Bucket: 'stellar-action-twitter',
  imagePrefix: 'https://stellar-action.s3.amazonaws.com/',
  encrypt_text:'cHangeTmrW21'
};
