import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { SocialAuthService, GoogleLoginProvider, FacebookLoginProvider } from 'angularx-social-login';
import { Observable } from 'rxjs';
import { User } from '../provider/user';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

    public isSocaialLogin = false;
    user: User;
    public loginuser;

    constructor(private http: HttpClient, private socailAuthService: SocialAuthService, private router: Router) {

        this.user = {
        
            email: '',
            firstname: '',
            lastname: '',
            phone: '',
            username: ''
        };
    }


    loggeduser(user){
      this.loginuser = user;
      return this.loginuser;
    }


    createVerifiedUser(user: User) {
        const headerObject = {
          headers: new HttpHeaders().set(
            'Content-Type',
            'application/json'
          )
        };
        return this.http.post('/login/create-verified-user', user, headerObject);
      }

    signInWithGoogle(): void {
        this.isSocaialLogin = true;
        this.socailAuthService.signIn(GoogleLoginProvider.PROVIDER_ID).then(x => {
          this.user.firstname = x.name;
          this.user.email = x.email;
          this.user.username = x.email;
          
          const consumerInfo = {consumerEmail:x.email};
          localStorage.setItem("consumerInfo", JSON.stringify(consumerInfo));
          this.user.userType = 'social-g';
          this.createVerifiedUser(this.user).subscribe((resp) => {
            if (resp !== null) {
              resp['userType'] =  this.user.userType;
              const userdata = JSON.stringify(resp);
              localStorage.setItem('minicrmuser', userdata);
            }
        });
        });
      }
  
      signInWithFB(): void {
        this.isSocaialLogin = true;
        this.socailAuthService.signIn(FacebookLoginProvider.PROVIDER_ID).then(x => {
            this.user.firstname = x.name;
          this.user.email = x.email;
          this.user.username = x.email;
          const consumerInfo = {consumerEmail:x.email};
          localStorage.setItem("consumerInfo", JSON.stringify(consumerInfo));
          this.user.userType = 'social-f';
          this.createVerifiedUser(this.user).subscribe((resp) => {
            if (resp !== null) {
              resp['userType'] =  this.user.userType;
              const userdata = JSON.stringify(resp);
              localStorage.setItem('minicrmuser', userdata);
            }
        });
        });
      }





}