import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './provider/guard/auth.guard';
import { CanActivate, CanActivateChild } from '@angular/router';

const routes: Routes = [
{
path: '',
redirectTo: 'homepage',
pathMatch: 'full'
},

{
  path: 'online',
  canActivate: [AuthGuard],
  loadChildren: () => import('./onlineaction/layout/layout.module').then(m => m.LayoutModule)
},
{
  path: 'donor',
  canActivateChild: [AuthGuard],
  loadChildren: () => import('./donar/layout/layout.module').then(m => m.LayoutModule),
},
{
path: 'editor',
loadChildren: () => import('./editor/editormodule.module').then(m => m.EditormoduleModule)
},
{
path: 'donate/:slugurl',
loadChildren: () => import('./donate/donatemodule.module').then(m => m.DonateModule)
},
{
path: 'test',
loadChildren: () => import('./testpage/testpage.module').then(m => m.TestpageModule)
},
{
path: '',
loadChildren: () => import('./homepage/homepage.module').then(m => m.HomePageModule)

},
{
path: 'signup',
loadChildren: () => import('./registration/registration.module').then(m => m.RegistrationModule)
},
{
path: 'login',
loadChildren: () => import('./login/login.module').then(m => m.LoginModule)
},
{
path: 'contactus',
loadChildren: () => import('./contactus/contactus.module').then(m => m.ContactusModule)
},
{
    path: 'aboutus',
    loadChildren: () => import('./aboutus/aboutus.module').then(m => m.AboutusModule)
},
{
path: 'privacypolicy',
loadChildren: () => import('./privacypolicy/privacypolicy.module').then(m => m.PrivacypolicyModule)
},
{
path: 'termservices',
loadChildren: () => import('./termservices/termservices.module').then(m => m.TermservicesModule)
},

{
path: 'forgotpassword',
loadChildren: () => import('./forgotpassword/forgotpassword.module').then(m => m.ForgotPasswordModule)
},
{
path: 'resetpassword',
loadChildren: () => import('./resetpassword/resetpassword.module').then(m => m.ResetPasswordModule)
},
{
path: 'enrollcandidate',
loadChildren: () => import('./enrollcandidate/enrollcandidate.module').then(m => m.EnrollCandidateModule)

},
{
path: 'createpassword',
loadChildren: () => import('./createpassword/createpassword.module').then(m => m.CreatePasswordModule)
},
{
path: 'userdashboard',
loadChildren: () => import('./userdashboard/userdashboard.module').then(m => m.UaserdashboardModule)
},
{
  path: 'nonprofit-charity',
  loadChildren: () => import('./nonprofit-charity/nonprofit-charity.module').then(m => m.NonprofitCharityModule)

},
{
path: 'enrollotherorganization',
loadChildren: () => import('./enrollotherorganisation/enrollotherorganisation.module').then(m => m.EnrollotherorganisationModule)

},
];
@NgModule({
imports: [RouterModule.forRoot(routes)],
exports: [RouterModule]
})
export class AppRoutingModule { }
